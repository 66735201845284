/**
 * Serves as middleware for user event tracking API(s):
 * Google Analytics (https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits)
 */
export default Object.freeze({
  /**
   * Track an arbitrary event name and notify external services
   * eg. Google Analytics
   */
  track(eventLabel, eventCategory, eventAction = 'click') {
    if (!eventLabel || !eventCategory) {
      console.error('eventLabel and eventCategory are required paremeters for GA events.');
      return;
    }

    if (this._pageHasGoogleAnalytics()) {
      window.ga('send', { hitType: 'event', eventCategory, eventAction, eventLabel });
    }
  },

  /**
   * Track a page view in Google analytics. It currently uses the window.location
   * parameter to determine the page we want to track, and substitutes id
   * numbers in the URL out to allow for better grouping in GA
   */
  pageView(from) {
    if (this._pageHasGoogleAnalytics()) {
      const location = window.location.pathname.replace(/\/\d+/g, '');
      window.ga('send', 'pageview', location);
    }

    // zd.core already sends stats on initial page load
    if (from?.path !== '/') {
      window?.zd?.core?.utilities?.spaPageViewSignal();
    }
  },

  /**
   * Determine if the global Google Analytics obejct has loaded. This
   * is loaded via a Rails partial in the HTML
   */
  _pageHasGoogleAnalytics() {
    return window.ga && typeof window.ga === 'function';
  },
});
